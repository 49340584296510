import React, { useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import Title from '../Title/Title';
import __ from '../../helpers/translate';
import { Tooltip } from 'react-tooltip';


const Contact = () => {
  const { contact } = useContext(PortfolioContext);
  const { email, phone } = contact;

  return (
    <section id="contact">
      <Container>
        <Title title={__('contact')} />
        <Fade bottom duration={500} delay={300} distance="30px">
          <div className="contact-wrapper">
            <p className="contact-wrapper__text">
              {__('contact', 'cta') || 'Would you like to work with me? Awesome!'}
            </p>

            <a
              data-tooltip-id="contact-tooltip"
              data-tooltip-content={email}
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href={email ? `mailto:${email}` : 'https://millsoft.de'}
            >
              {__('contact', 'btnEmail')}
            </a>

            <a
              data-tooltip-id="contact-tooltip"
              data-tooltip-content={phone}
              target="_blank"
              rel="noopener noreferrer"
              className="cta-btn cta-btn--resume"
              href={`tel:${phone}`}
              style={{ marginLeft: '1rem' }}
            >
              {__('contact', 'btnPhone')}
            </a>
            </div>
            <p className="mt-5">
              {__('contact', 'additional')}
            </p>
        </Fade>
        <Tooltip
                style={{ fontSize: '1.5rem'}} 
                id="contact-tooltip" />
      </Container>
    </section>
  );
};

export default Contact;
