import React, { useContext, useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import { Container, Row, Col } from 'react-bootstrap';
import Title from '../Title/Title';
import AboutImg from '../Image/AboutImg';
import PortfolioContext from '../../context/context';
import __ from '../../helpers/translate';
import Icon from '../Icon/Icon';

const About = () => {
  const { about } = useContext(PortfolioContext);
  const { img, cv } = about;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  const aboutItems = __("about", "info")
  const cvUrl = __('about', 'cv');
  const txtCv = __('cv');

  return (
    <section id="about" className="dark">
      <Container>
        <Title title={__("about_me")} />
        <Row className="about-wrapper">
          <Col md={6} sm={12}>
            <Fade bottom duration={500} delay={200} distance="30px">
              <div>
                <div className="about-wrapper__image">
                  <AboutImg alt="profile picture" filename={img} />
                </div>
                <div className='cv-button-wrapper'>
                {cv && (
                  <span className="d-flex mt-3 mb-3">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cta-btn cta-btn--resume"
                      href={cvUrl}
                    >
                      <Icon icon="file" /> {txtCv}
                    </a>
                  </span>
                )}
                </div>
                </div>
            </Fade>

            
          </Col>
          <Col md={6} sm={12}>
            <Fade left={isDesktop} bottom={isMobile} duration={500} delay={500} distance="30px">
              <div className="about-wrapper__info">

              {aboutItems && aboutItems.map( (paragraph, index) => {
                return (
                <p className="about-wrapper__info-text" key={index}>
                    {paragraph}
                </p>
                )
              })}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default About;
